import React, { useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Button, Tabs } from 'antd';
import { FaBuilding, FaWrench, FaPaintBrush } from 'react-icons/fa';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '../components/layout';
import Seo from '../components/seo';

// img
import restorationImg from '../images/restoration/header-restoration.jpg';

const RestorationHeader = styled.div`
  color: #ffffff;
  width: 100%;
  height: 50em;
  background-image: url(${restorationImg});
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position-x: 50%;
  background-position-y: 50%;
`;

const DContainer = styled.div`
  max-width: 90%;
  width: 980px;
  margin: 0 auto;
`;

const RestorationHeaderEffect = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const RestorationTitleSpan = styled.span`
  font-family: 'Open Sans Condensed';
  font-size: 3.75rem;
  font-weight: 600;
  font-stretch: 100%;
  text-size-adjust: 100%;
  display: inline;
`;

const RestorationTitle = styled.div`
  width: 100%;
  height: 125px;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RestorationLogo = styled.div`
  display: flex;
  text-align: center;
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 30px 30px 30px 30px;
`;

const RestorationIntroWrapper = styled.div`
  width: 100%;
  height: calc(100% - 125px);
  background-color: rgba(255, 255, 255, 0.9);
`;

const RestorationIntro = styled.div`
  max-width: 90%;
  width: 980px;
  height: 100%;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  grid-template-areas:
    'main art'
    'link art';
  grid-template-columns: 1fr 0.3fr;
  grid-template-rows: 1fr 0.5fr;
`;

const IntroMain = styled.div`
  grid-area: main;
  padding-top: 2rem;
`;

const IntroMainH1 = styled.h1`
  color: #333333 !important;
  font-size: 48px;
  font-weight: 100;
  margin-bottom: 1rem;
`;

const IntroMainSpan = styled.span`
  color: #000000;
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
`;

const IntroLinks = styled.div`
  grid-area: link;
`;

const IntroLinksH1 = styled.h1`
  color: #333333 !important;
  font-size: 28px;
  font-weight: 100;
  margin-bottom: 1rem;
`;

const IntroArt = styled.div`
  grid-area: art;
  padding-top: 2rem;
`;

const IntroArtButton = styled(Button)`
  border: none;
  width: 222px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  background-color: rgb(255, 215, 0);

  &:hover,
  &:focus {
    background-color: #000;
    color: rgb(255, 215, 0);
    border: 1px solid rgb(255, 215, 0);
  }
`;

const IntroLinksButton = styled(Button)`
  margin-right: 0.5rem;
  border: none;
  padding-left: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  background-color: rgb(255, 215, 0);

  &:hover,
  &:focus {
    background-color: #000;
    color: rgb(255, 215, 0);
    border: 1px solid rgb(255, 215, 0);
  }
`;

const RestoreWrapper = styled.article`
  padding: 4rem 0;
`;

const RestoreGroup = styled.div`
  display: grid;
  grid-template-areas: 'text image';
  grid-template-columns: 1fr 0.4fr;
  gap: 1rem;
`;

const RestoreButton = styled(Button)`
  border: none;
  font-weight: 600 !important;
  text-transform: uppercase;
  color: #000;
  background-color: rgb(255, 215, 0);

  &:hover,
  &:focus {
    background-color: #000;
    color: rgb(255, 215, 0);
    border: 1px solid rgb(255, 215, 0);
  }
`;

const RestoreText = styled.div`
  grid-area: 'text';
  padding-bottom: 4rem;
`;

const RestoreTextH1 = styled.h1`
  font-weight: 100;
  margin-bottom: 1rem;
`;

const RestoreTextp = styled.p`
  font-weight: 100;
  margin-bottom: 1rem;
  font-size: 16px;
  color: #666666;
`;

const RestoreTitle = styled.div`
  color: #333333;
  border-bottom: 1px solid #333333;
`;

const RestoreTextImage = styled.div`
  width: 100%;
`;

const RestoreImage = styled.div`
  padding-top: 46px;
`;

const RestoreImageP = styled.p`
  color: #333333;
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: 600;
`;

const RestoreWrapperRepair = styled.article`
  background-color: #f0f0f0;
  padding: 4rem 0;

  .ant-tabs-tab-active > .restore-tab {
    background-color: rgb(255, 215, 0);
    color: #000;
  }

  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    padding: 4px 12px 4px 4px;
  }
`;

const RestoreTabsWrapper = styled.div`
  padding: 1rem 0;
`;

const RestoreTabsWrapperH2 = styled.h2`
  font-size: 21px;
`;

const RestoreTabs = styled.div`
  padding: 1rem 1rem;
  background-color: #ffffff;
`;

const RestoreTab = styled.div`
  padding: 4px 12px;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgb(255, 215, 0);
    color: #000;
  }
`;

const RestoreTabSpanFirst = styled.span`
  &:first-child {
    font-size: 24px;
    font-weight: 600;
    margin-right: 12px;
  }
`;
const RestoreTabSpanLast = styled.span`
  &:last-child {
    text-align: center;
    font-weight: 600;
  }
`;

const TabPaneStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.4fr;
  gap: 2rem;
`;

const TabPaneText = styled.div`
  color: #666666;
`;

const TabPaneTextTitleSpan = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

const TabPaneTextSubtitle = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #666666;
`;

const TabPaneTextP = styled.p`
  font-size: 16px;
  font-weight: 100;
  color: #666666;
`;

const TabPaneSeparator = styled.div`
  width: 100%;
  border-top: 1px solid #333333;
`;

const { TabPane } = Tabs;

const scrollToRef = (ref) => ref.current && window.scrollTo(0, ref.current.offsetTop - 200);

const Restoration = React.memo(() => {
  const aestheticsRef = useRef(null);
  const repairRef = useRef(null);
  const transRef = useRef(null);
  const executeScroll = (ref) => scrollToRef(ref);
  const data = useStaticQuery(graphql`
    {
      reStore: file(relativePath: { eq: "restoration/2_2_0_0_Restore_Logo_1-300x124.png" }) {
        childImageSharp {
          gatsbyImageData(height: 53, layout: FIXED)
        }
      }
      miniHeader: file(relativePath: { eq: "restoration/restore_cover_header-300x165.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 208, quality: 100, layout: FIXED)
        }
      }
      artAll: file(relativePath: { eq: "restoration/ART-All.png" }) {
        childImageSharp {
          gatsbyImageData(width: 223, quality: 100, layout: FIXED)
        }
      }
      artA: file(relativePath: { eq: "restoration/ART-A.png" }) {
        childImageSharp {
          gatsbyImageData(width: 223, quality: 100, layout: FIXED)
        }
      }
      artR: file(relativePath: { eq: "restoration/ART-R.png" }) {
        childImageSharp {
          gatsbyImageData(width: 223, quality: 100, layout: FIXED)
        }
      }
      artT: file(relativePath: { eq: "restoration/ART-T.png" }) {
        childImageSharp {
          gatsbyImageData(width: 223, quality: 100, layout: FIXED)
        }
      }
      aesthetics: file(relativePath: { eq: "restoration/reStore_Aesthetics.png" }) {
        childImageSharp {
          gatsbyImageData(width: 689, quality: 100, layout: CONSTRAINED)
        }
      }
      repair: file(relativePath: { eq: "restoration/reStore_Repair_img1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 689, quality: 100, layout: CONSTRAINED)
        }
      }
      restoreOne: file(relativePath: { eq: "restoration/restore_level_1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 168, quality: 100, layout: CONSTRAINED)
        }
      }
      restoreTwo: file(relativePath: { eq: "restoration/restore_level_2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 168, quality: 100, layout: CONSTRAINED)
        }
      }
      restoreThree: file(relativePath: { eq: "restoration/restore_level_3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 168, quality: 100, layout: CONSTRAINED)
        }
      }
      restoreFour: file(relativePath: { eq: "restoration/restore_level_4.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 168, quality: 100, layout: CONSTRAINED)
        }
      }
      transform: file(relativePath: { eq: "restoration/transformation_image2-1024x622.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 301, quality: 100, layout: FIXED)
        }
      }
    }
  `);
  return (
    <Layout>
      <Seo title="Restauracion" />
      <RestorationHeader>
        <RestorationHeaderEffect>
          <DContainer>
            <RestorationTitle>
              <RestorationTitleSpan>Restauración</RestorationTitleSpan>
              <RestorationLogo>
                <GatsbyImage
                  image={data.reStore.childImageSharp.gatsbyImageData}
                  alt="logo-reStore"
                />
              </RestorationLogo>
            </RestorationTitle>
          </DContainer>
          <RestorationIntroWrapper>
            <RestorationIntro>
              <IntroMain>
                <IntroMainH1>El A.R.T. de reStore™</IntroMainH1>
                <IntroMainSpan>Estética. Reparar. Transformar.</IntroMainSpan>
                <div style={{ marginTop: '1rem' }}>
                  <GatsbyImage
                    image={data.miniHeader.childImageSharp.gatsbyImageData}
                    style={{ float: 'left', margin: '0 20px 10px 0' }}
                    alt="logo-miniHeader"
                  />
                  <IntroMainSpan
                    style={{
                      fontWeight: 100,
                      fontSize: '18px',
                      lineHeight: '28px',
                    }}
                  >
                    <IntroMainSpan
                      style={{
                        fontSize: '24px',
                        fontWeight: 100,
                        lineHeight: '34px',
                      }}
                    >
                      Un enfoque completo de la restauración.
                    </IntroMainSpan>
                    <br />
                    Tradicionalmente, la restauración de edificios ha sido poco más que una larga
                    lista de tareas. Sto®, un líder en soluciones innovadoras para la restauración
                    de fachadas de edificios, ahora ofrece la solución completa e integral en el
                    mercado: El A.R.T. de reStore™, que ofrece una fuente de materiales y servicios
                    para atender todas sus necesidades de mantenimiento y restauración.
                  </IntroMainSpan>
                </div>
              </IntroMain>
              <IntroLinks>
                <IntroLinksH1>Consulte más sobre el A.R.T. de restauración</IntroLinksH1>
                <div>
                  <IntroLinksButton
                    type="primary"
                    size="large"
                    icon={<FaPaintBrush style={{ marginRight: '8px', font: '1em' }} />}
                    onClick={() => executeScroll(aestheticsRef)}
                  >
                    Estética
                  </IntroLinksButton>
                  <IntroLinksButton
                    type="primary"
                    size="large"
                    icon={<FaBuilding style={{ marginRight: '8px', font: '1em' }} />}
                    onClick={() => executeScroll(repairRef)}
                  >
                    Reparar
                  </IntroLinksButton>
                  <IntroLinksButton
                    type="primary"
                    size="large"
                    icon={<FaWrench style={{ marginRight: '8px', font: '1em' }} />}
                    onClick={() => executeScroll(transRef)}
                  >
                    Transformar
                  </IntroLinksButton>
                </div>
              </IntroLinks>
              <IntroArt>
                <GatsbyImage
                  image={data.artAll.childImageSharp.gatsbyImageData}
                  alt="logo-artAll"
                />
                <IntroArtButton type="primary" size="large">
                  ¿Porque Restaurar?
                </IntroArtButton>
              </IntroArt>
            </RestorationIntro>
          </RestorationIntroWrapper>
        </RestorationHeaderEffect>
      </RestorationHeader>
      <RestoreWrapper>
        <DContainer>
          <RestoreGroup>
            <RestoreText>
              <RestoreTitle>
                <RestoreTextH1 ref={aestheticsRef}>
                  <strong>Estética</strong> | En Sto®, hay un arte en ello.
                </RestoreTextH1>
              </RestoreTitle>
              <RestoreTextp style={{ marginTop: '10px' }}>
                Para nosotros, el proceso comienza con los gráficos e ideas que te inspiran. Usted
                elige la estética - el carácter, el color y la forma de su edificio - y vamos a
                proporcionar los productos que realizan su visión de diseño. Podemos convertir su
                inspiración creativa en un sistema de revestimiento hermoso, sostenible y
                completamente diseñado.
              </RestoreTextp>
              <RestoreTextp>
                La promesa? Libertad total de diseño en el color, la textura, el acabado y los
                materiales. La clave para liberar todo el potencial de su proyecto de restauración
                es la idea de que todo es posible.
              </RestoreTextp>
              <RestoreTextImage>
                <GatsbyImage
                  image={data.aesthetics.childImageSharp.gatsbyImageData}
                  alt="logo-aesthetics"
                />
              </RestoreTextImage>
            </RestoreText>
            <RestoreImage>
              <RestoreImageP>El A.R.T. de reStore</RestoreImageP>
              <GatsbyImage image={data.artA.childImageSharp.gatsbyImageData} alt="logo-artA" />
            </RestoreImage>
          </RestoreGroup>
        </DContainer>
      </RestoreWrapper>
      <RestoreWrapperRepair>
        <DContainer>
          <RestoreGroup>
            <RestoreText>
              <RestoreTitle>
                <RestoreTextH1 ref={repairRef}>
                  <strong>Reparar</strong> | No lo ignore, restaurelo.
                </RestoreTextH1>
              </RestoreTitle>
              <RestoreTextp style={{ marginTop: '10px' }}>
                Cualquiera que sea el estado del exterior de su edificio, reStore tiene una solución
                para ayudarle a recuperar el valor de su inversión y cumplir con las nuevas
                regulaciones ambientales. Los cuatro niveles de reStore cubren todo, desde un simple
                mantenimiento para eliminar la suciedad y el moho hasta una completa restauración de
                la fachada con todos los productos y el apoyo que necesitas.
              </RestoreTextp>
              <RestoreTextp>
                Con la completa Guía de Reparación de Sto, 30 de los problemas más comunes que
                afectan a EIFS, estuco, hormigón y mampostería se abordan con soluciones ilustradas
                paso a paso. <br />
                <br /> Solicite una copia digital de la Guía de Reparación de Sto, la única de su
                tipo en la industria.
              </RestoreTextp>
              <RestoreButton type="primary" size="large">
                Guia de Reparación Completa
              </RestoreButton>
            </RestoreText>
            <RestoreImage>
              <RestoreImageP>El A.R.T. de reStore</RestoreImageP>
              <GatsbyImage image={data.artR.childImageSharp.gatsbyImageData} alt="logo-artR" />
            </RestoreImage>
          </RestoreGroup>
          <RestoreTabsWrapper>
            <RestoreTabsWrapperH2>Los Cuatro Niveles de reStore</RestoreTabsWrapperH2>
            <RestoreTabs>
              <Tabs tabPosition="left" tabBarGutter={5}>
                <TabPane
                  tab={
                    <RestoreTab>
                      <RestoreTabSpanFirst>1</RestoreTabSpanFirst>
                      <RestoreTabSpanLast>Limpiar y Recubrir</RestoreTabSpanLast>
                    </RestoreTab>
                  }
                  key="1"
                >
                  <TabPaneStyle>
                    <TabPaneText>
                      <TabPaneTextTitleSpan>Limpiar y Recubrir</TabPaneTextTitleSpan>
                      <TabPaneSeparator />
                      <TabPaneTextSubtitle>
                        Un programa básico para eliminar la suciedad, el moho y los hongos, mientras
                        se refresca o actualiza el color de la fachada.
                      </TabPaneTextSubtitle>
                      <TabPaneTextP>
                        El proceso de limpieza y repintado combina un lavado a presión y un acabado
                        de la superficie con un recubrimiento Sto de alto rendimiento. Los
                        recubrimientos de Sto ofrecen mucho más que pintura; en realidad resisten la
                        acumulación de suciedad, moho y contaminantes, preservando el valor de un
                        edificio al tiempo que mejoran su apariencia y reducen las necesidades de
                        mantenimiento. Los recubrimientos de Sto están coloreados integralmente con
                        pigmentos de alta calidad para complementar las adiciones del edificio o
                        para transformar totalmente la apariencia de una estructura. <br /> <br />{' '}
                        Usted verá resultados reales y cuantificables cuando se protege su edificio
                        con un revestimiento de alto rendimiento de Sto, incluyendo una mejor
                        resistencia al agua, al moho, a las grietas y a la decoloración.
                      </TabPaneTextP>
                    </TabPaneText>
                    <div>
                      <GatsbyImage
                        image={data.restoreOne.childImageSharp.gatsbyImageData}
                        alt="logo-restoreOne"
                      />
                    </div>
                  </TabPaneStyle>
                </TabPane>
                <TabPane
                  tab={
                    <RestoreTab>
                      <RestoreTabSpanFirst>2</RestoreTabSpanFirst>
                      <RestoreTabSpanLast>Reparación y Acabados</RestoreTabSpanLast>
                    </RestoreTab>
                  }
                  key="2"
                >
                  <TabPaneStyle>
                    <TabPaneText>
                      <TabPaneTextTitleSpan>Reparación y Acabado</TabPaneTextTitleSpan>
                      <TabPaneSeparator />
                      <TabPaneTextSubtitle>
                        Un programa para reparar daños menores en EIFS o fachadas de estuco antes de
                        que se convierta en un problema mayor.
                      </TabPaneTextSubtitle>
                      <TabPaneTextP>
                        Las fisuras y otros daños se reparan con un producto de reparación
                        específico para el sustrato. A menudo, una capa adicional de base con malla
                        embebida se utiliza para restaurar el exterior del edificio a su condición
                        original. Los productos de reparación de Sto están diseñados para la mayoría
                        de los sustratos, incluyendo EIFS, estuco, hormigón y mampostería. <br />{' '}
                        <br />
                        Después de reparar el daño, se aplica un acabado Sto de alto rendimiento
                        para terminar y sellar la superficie, protegiéndola de los elementos y
                        frenando el desgaste natural. Ahora el exterior es resistente a la lluvia
                        impulsada por el viento, los rayos UV, el moho y el hongos.
                      </TabPaneTextP>
                    </TabPaneText>
                    <div>
                      <GatsbyImage
                        image={data.restoreTwo.childImageSharp.gatsbyImageData}
                        alt="logo-restoreTwo"
                      />
                    </div>
                  </TabPaneStyle>
                </TabPane>
                <TabPane
                  tab={
                    <RestoreTab>
                      <RestoreTabSpanFirst>3</RestoreTabSpanFirst>
                      <RestoreTabSpanLast>Revestimiento</RestoreTabSpanLast>
                    </RestoreTab>
                  }
                  key="3"
                >
                  <TabPaneStyle>
                    <TabPaneText>
                      <TabPaneTextTitleSpan>Revestimiento</TabPaneTextTitleSpan>
                      <TabPaneSeparator />
                      <TabPaneTextSubtitle>
                        Un programa rentable para corregir los problemas de intrusión de humedad,
                        mejorar la eficiencia energética y el atractivo estético de las fachadas de
                        ladrillo, bloque o estuco.
                      </TabPaneTextSubtitle>
                      <TabPaneTextP>
                        Los revestimientos de muro con aislamiento de Sto tienen el poder de
                        transformar un edificio antiguo antiestético e ineficiente. La última
                        innovación de aislamiento continuo de Sto es StoTherm® ci Lotusan®, que
                        integra tres componentes clave en un sistema de revestimiento de paredes
                        aisladas de eficiencia energética: StoGuard® barrera continua de aire
                        aplicada con fluido, StoTherm® revestimiento exterior aislado y Stolit®
                        Lotusan® acabado texturizado con propiedades patentadas de auto-limpieza.
                        Este sistema de última generación se mantiene atractivo durante años, además
                        de aumentar significativamente la eficiencia de la calefacción y la
                        refrigeración térmicas, al tiempo que mejora la calidad del aire interior y
                        reduce los costos generales del ciclo de vida de la edificación.
                      </TabPaneTextP>
                    </TabPaneText>
                    <div>
                      <GatsbyImage
                        image={data.restoreThree.childImageSharp.gatsbyImageData}
                        alt="logo-restoreThree"
                      />
                    </div>
                  </TabPaneStyle>
                </TabPane>
                <TabPane
                  tab={
                    <RestoreTab>
                      <RestoreTabSpanFirst>4</RestoreTabSpanFirst>
                      <RestoreTabSpanLast>Retirar y Reclutar</RestoreTabSpanLast>
                    </RestoreTab>
                  }
                  key="4"
                >
                  <TabPaneStyle>
                    <TabPaneText>
                      <TabPaneTextTitleSpan>Retirar y Volver a Colocar</TabPaneTextTitleSpan>
                      <TabPaneSeparator />
                      <TabPaneTextSubtitle>
                        Para renovaciones extensas, a menudo es más eficiente empezar de nuevo o con
                        un nuevo revestimiento.
                      </TabPaneTextSubtitle>
                      <TabPaneTextP>
                        Sto le ofrece opciones. En lugar de reparaciones extensas, a menudo es más
                        eficiente para eliminar todos los revestimientos existentes y sustituirlos
                        por un sistema superior y sostenible de revestimiento de paredes de Sto que
                        es duradero, mejora el rendimiento del edificio y se ve fresco por mucho más
                        tiempo. <br />
                        <br />
                        Cuando tiene más sentido para reclamar que la reparación, un sistema de
                        revestimiento de la pared de Sto es el bajo mantenimiento, la solución de
                        alto rendimiento. Sto también puede revestir paredes de ladrillo, bloque y
                        estuco, mejorando su apariencia desde al acera y extendiende la vida útil
                        del exterior del edificio.
                      </TabPaneTextP>
                    </TabPaneText>
                    <div>
                      <GatsbyImage
                        image={data.restoreFour.childImageSharp.gatsbyImageData}
                        alt="logo-restoreFour"
                      />
                    </div>
                  </TabPaneStyle>
                </TabPane>
              </Tabs>
            </RestoreTabs>
          </RestoreTabsWrapper>
          <RestoreTextImage>
            <GatsbyImage image={data.repair.childImageSharp.gatsbyImageData} alt="logo-repair" />
          </RestoreTextImage>
        </DContainer>
      </RestoreWrapperRepair>
      <RestoreWrapper>
        <DContainer>
          <RestoreGroup>
            <RestoreText>
              <RestoreTitle>
                <RestoreTextH1 ref={transRef} style={{ lineHeight: '40px' }}>
                  <strong>Transformar</strong> | Transformar el rendimiento de su edificio. La
                  adaptabilidad es la clave de la resistencia.
                </RestoreTextH1>
              </RestoreTitle>
              <div style={{ marginTop: '24px' }}>
                <GatsbyImage
                  image={data.transform.childImageSharp.gatsbyImageData}
                  style={{ float: 'right', margin: '0px 0px 0px 10px' }}
                  alt="logo-transform"
                />
                <RestoreTextp>
                  Varios estudios han demostrado que los edificios existentes representan hasta el
                  75% de las emisiones de gases de efecto invernadero de una gran ciudad. Como las
                  grandes ciudades emplean estrategias de protección del clima, la profunda
                  renovación energética de los edificios existentes es una de sus principales
                  prioridades. Organizaciones como LEED, Retrofit New York y Passive House están de
                  acuerdo en que un sistema de fachada completamente diseñado con un
                  <strong> aislamiento continuo </strong> y una{' '}
                  <strong> envoltura hermética </strong> envoltura hermética es la única manera de
                  que los edificios existentes cumplan con las estrictas normas de eficiencia
                  energética que ya han comenzado a desplegarse.
                </RestoreTextp>
                <RestoreTextp>
                  Una profunda retroadaptación energética transforma el rendimiento medioambiental y
                  económico de un edificio:
                </RestoreTextp>
                <ul style={{ fontWeight: '100', fontSize: '16px' }}>
                  <li>Reducción de la huella total de carbono (incorporado y en funcionamiento)</li>
                  <li>Menores costos de utilidades</li>
                  <li>Aumento de las primas de alquiler y del valor de la propiedad</li>
                  <li>Mejora de la comodidad y la productividad de los ocupantes</li>
                </ul>
              </div>
            </RestoreText>
            <RestoreImage>
              <RestoreImageP>El A.R.T. de reStore</RestoreImageP>
              <GatsbyImage image={data.artT.childImageSharp.gatsbyImageData} alt="logo-artT" />
            </RestoreImage>
          </RestoreGroup>
        </DContainer>
      </RestoreWrapper>
    </Layout>
  );
});

export default Restoration;
